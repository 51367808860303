import React from "react";
import { abstract1, star } from "../assets";

interface HeaderProps {
    toggleDiscoverOffers: React.Dispatch<React.SetStateAction<boolean>>
}
export default function Header(
    { toggleDiscoverOffers }: HeaderProps
) {
    return <header className="bg-[#2C1749] text-white">
        <div className="brandomi-container relative py-20 lg:py-0 lg:h-[40rem] flex flex-col justify-center gap-2 lg:gap-8 overflow-hidden">
            <img src={abstract1} alt="abstract"
                className="absolute -right-24 md:right-0 bottom-0 h-72 md:h-96 lg:h-[35rem]" />

            <div className="flex lg:flex-row flex-col lg:items-center gap-2 relative">
                <img src={star} alt="star" className="w-fit h-4 lg:h-6" />
                <p className="text-[#CCCCCC] text-xs">Trusted by <span className="font-semibold text-white">30+</span> companies</p>

            </div>
            <h1 className="text-3xl lg:text-5xl xl:text-7xl font-fine relative font-semibold">Seamless solution <br />
                with our magic!</h1>
            <h5 className="md:max-w-[17rem] max-w-[15rem] min-[375px]:max-w-[16rem] lg:mt-0 mt-3 lg:max-w-lg text-sm lg:text-2xl relative">Brandomi is a comprehensive Business Operation Service Providers for enterprises looking to scale aggressively and achieve rapid profitability.</h5>

            <div className="flex flex-col lg:flex-row lg:mt-0 mt-3 lg:items-center gap-6 relative">
                <p className="flex items-center gap-3 text-xs">
                    <span className="h-6 border-r-2 border-[#FFFFFF]/40" />
                    Start efficiently growing <br />
                    your company with Us!</p>
                <button onClick={() => toggleDiscoverOffers(true)} className="bg-[#4DB39C] py-2 px-4 w-fit">Discover our offers</button>
            </div>
        </div>
    </header>
}