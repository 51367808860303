import React from "react";
import Slider, { Settings } from "react-slick";
import { profile1, profile2, profile3, quote } from "../assets";
import { whatCustomersSay } from "../constants/feedbacks";
import GetStartedForm from "./widgets/GetStartedForm";

const settings: Settings = {
    autoplay: true,
    slidesToShow: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    speed: 2000,
    autoplaySpeed: 5000,
}

interface SubFooterProps {
    toggleDiscoverOffers: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SubFooter( { toggleDiscoverOffers }: SubFooterProps) {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const profiles = [profile1, profile2, profile3];
    const slider = React.useRef<Slider>(null);

    return <section className="brandomi-container py-10 lg:grid gap-10 lg:grid-cols-2 text-white">
        <div className="">
            <h1 className="font-fine text-3xl lg:text-5xl">What People are Saying About Us</h1>

            <img src={quote} alt="quote" className="w-10 my-7" />
            <div>
                <Slider ref={slider} {...settings} beforeChange={(curr, next) => setCurrentSlide(next)}>
                    {
                        whatCustomersSay.map(
                            (feedback, index) =>
                                <div className="md:pr-10" key={"feedback" + index}>
                                    <div className="grid">
                                        <p className="text-lg">{feedback.message}</p>
                                        <p className="text-lg mt-4">{feedback.name}</p>
                                        <p className="text-[#54BD95] italic">{feedback.company}</p>
                                    </div>
                                </div>
                        )
                    }
                </Slider>
            </div>

            <div className="flex gap-3 mt-5">
                {
                    profiles.map(
                        (profile, index) =>
                            <div key={"profile" + index}
                                onClick={() => slider.current?.slickGoTo(index)}
                                className={`w-12 cursor-pointer h-12 rounded-full relative overflow-hidden ${index === currentSlide ? "ring-2 ring-[#54BD95]" : ""}`}>
                                <img src={profile} alt="prof" className="w-full h-full rounded-full" />
                                {index === currentSlide ? <></> : <div className="absolute w-full h-full backdrop-blur-[1px] top-0" />}
                            </div>
                    )
                }
            </div>
        </div>

        <GetStartedForm  toggleDiscoverOffers={toggleDiscoverOffers}  />
    </section>
}