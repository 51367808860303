export const whatCustomersSay = [
    {
        message : "A creative- marketing agency like Brandomi is what I believe every startup founder should have by their side, I'm convinced they have a strong value proposition with a very clear strategy of how they plan to achieve corporate revenue objectives for businesses",
        name : "Olushina Femi",
        company : "Flexport"
    },
    {
        message : "Teju and the lads in the team are such an exciting group. I've been opportuned with a good number of members of the team. And I can attest to the fact that they are an extremely dedicated group of young individuals ready to go all length to solve complex challenges in the most effective way.",
        name : "Adegoke Damilola (Dacode)",
        company : "SoFi"
    },
    {
        message : "I believe any concerned business owner that needs major relief from the heck of daily business operations should already be here striking a deal. I personally love their holistic approach to solving all possible problems you can have as a business",
        name : "Babatunde Olatunji",
        company : "Figure"
    }
] 