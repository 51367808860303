const plans = [
    {
        name : "Basic",
        description : "Have a go and test our superpowers",
        sub_fee : "300,000 NGN",
        price : "150,000",
        details : [
            {
                name : "Content creation",
                sub_name : "",
                description : "Graphics Design and Content Writing"
            },
            {
                name : "Social Media Management",
                sub_name : "",
                description : "Social Media Strategy Development, Content Creation Excluding Video Content, Content Planning and Community Management"
            },
            {
                name : "Software Development",
                sub_name : "",
                description : "Landing Page With A Free Domain Name"
            },
            {
                name : "Brand Strategy and Branding", 
                sub_name : "(If/As Required)",
                description : "Brand Guidelines, Brand Identity and Brand Collateral"
            },
        ]
    },
    {
        name : "Pro",
        description : "Unveil newer superpowers and join the Pro Leagues",
        sub_fee : "600,000 NGN",
        price : "400,000",
        details : [
            {
                name : "Lead Generation and Acquisition",
                sub_name : "",
                description : "Market Research, Email Marketing, Cold Calling, Lead Magnet Generation and Direct Field Outreach"
            },
            {
                name : "Digital Marketing",
                sub_name : "",
                description : "Meta, Twitter, LinkedIn and Instagram Ads, Networking Events, Lead Qualifications & Follow-up"
            },
            {
                name : "Content creation",
                sub_name : "",
                description : "Video Production, Graphics Design and Content Writing"
            },
            {
                name : "Social Media Management",
                sub_name : "",
                description : "Social Media Strategy Development, Social Media Advertisement, Content Planning and Community Management"
            },
            {
                name : "Software Development",
                sub_name : "",
                description : "Business Website Development With Custom Domain"
            },
            {
                name : "Brand Strategy and Branding",
                sub_name : "",
                description : "Brand Guidelines, Brand Identity and Brand Collateral"
            },
        ]
    },
    {
        name : "Premium",
        description : "Experiment the power of infinite possibilities",
        sub_fee : "1,000,000 NGN",
        price : "800,000",
        details : [
            {
                name : "Lead Generation and Acquisition",
                sub_name : "",
                description : "Market Research, Email Marketing, Cold Calling, Lead Magnet Generation and Direct Field Outreach"
            },
            {
                name : "Digital Marketing",
                sub_name : "",
                description : "Meta, Twitter, LinkedIn and Instagram Ads, Networking Events, Lead Qualifications & Follow-up"
            },
            {
                name : "Content creation",
                sub_name : "",
                description : "Video Production, Graphics Design and Content Writing"
            },
            {
                name : "Social Media Management",
                sub_name : "",
                description : "Social Media Strategy Development, Social Media Advertisement, Content Planning and Community Management"
            },
            {
                name : "Software Development",
                sub_name : "",
                description : "Advanced Landing Page Development With Custom Domain"
            },
            {
                name : "Brand Strategy and Branding",
                sub_name : "",
                description : "Brand Guidelines, Brand Identity and Brand Collateral"
            },
        ]
    }
]

export default plans;

export type plan = typeof plans[number]