import React from "react"
import { copy } from "../../assets"

interface CopyTextProps {
    text: string,
    textToCopy: string
}
export default function CopyText({ text, textToCopy }: CopyTextProps) {
    const [copying, setCopying] = React.useState(false);

    function handleClick() {
        navigator.clipboard.writeText(textToCopy)
        setCopying(true)
        setTimeout(() => {
            setCopying(false)
        }, 2000)
    }

    return <p className="flex items-center text-sm md:text-base justify-between gap-5">{text}
        <button className="flex items-center gap-1" onClick={handleClick} disabled={copying}><img src={copy} className="w-4" alt="copy"/>{copying ? 'copying' : 'copy'}</button>
    </p>
} 