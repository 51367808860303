import PlanCard from "./widgets/PlanCard";
import plans from "../constants/priceplans";
import Carousel, { CarouselTab } from "./widgets/Carousel";

export default function Priceplan() {
    return <section className="bg-[#2C1749] text-white">
        <div className="brandomi-container py-10">
            <h1 className="text-center text-xl lg:text-2xl xl:text-4xl font-fine relative font-semibold">Choose Package <br />
                That’s Right For You</h1>
            <p className="text-center md:text-lg font-semibold text-sm mt-4">Feel free to contact us for your dynamic or distinct needs</p>

            <div className="grid mt-4">
                <div className="bg-white h-fit rounded-lg py-4 px-2 mx-auto">
                    <span className="bg-[#2C1749] rounded-lg p-2">Monthly Retainers</span>
                </div>
            </div>

            <div className="hidden lg:grid grid-cols-3 gap-10 mt-9 min-w-[100px]">
                {
                    plans.map((plan, index) => <PlanCard key={"plan" + index} data={plan} />)
                }
            </div>

            <div className="block lg:hidden mt-9">
                <Carousel>
                    {
                        plans.map((plan, index) => <CarouselTab key={"plan" + index}>
                            <PlanCard  data={plan} />
                        </CarouselTab>)
                    }
                </Carousel>
            </div>
        </div>
    </section>
}