import { collage } from "../assets"

export default function Recruitment() {
    return <section className="bg-[#D8C8EF] text-[#2C1749]">
        <div className="brandomi-container py-10 grid md:grid-cols-2 gap-16">
            <div className="flex flex-col gap-5 lg:pr-12 xl:pr-20 items-center md:items-start justify-center">
                <h1 className="font-fine text-3xl lg:text-5xl font-[500]">
                    Recruitment at <br className="xl:block hidden" /> Zero Cost!</h1>
                <p className="text-sm leading-6">We boast of an extensive talent pool from diverse industries and
                    exceptional talent sourcing capabilities. Our services include talent
                    acquisition, screening, in-depth interviews, and assessment to ensure
                    they are a perfect fit with your Company. Our services come with the
                    added benefit of a zero service fee, making it a cost-effective
                    option for organizations looking to streamline their recruitment efforts.</p>
                <button  className="w-fit bg-[#2C1749] text-white py-3 px-5 text-lg font-semibold">
                    Partner with Us
                </button>
            </div>

            <div className="flex items-center">
                <img alt="collage-pic" className="" src={collage}/>
            </div>
        </div>
    </section>
}