import React from "react"

interface ModalProps extends React.PropsWithChildren {
    show : boolean,
    toggle : () => void
}
export default function Modal ( {show, toggle, children} : ModalProps) {
    function handleClick (e : React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (e.target === e.currentTarget) {
            toggle()
        }
    }
    return show ?  <div onClick={handleClick} className="p-5 z-[1000] fixed text-[#2C1749] top-0 w-full h-screen bg-slate-700/40 flex justify-center items-center">
        {children}
    </div> : <></>
}