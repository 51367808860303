import { close } from "../../assets"

interface sidebarProps {
    show : boolean,
    toggle : () => void,
    toggleContactModal: React.Dispatch<React.SetStateAction<boolean>>,
    toggleDiscoverOffers: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Sidebar({show, toggle, toggleContactModal, toggleDiscoverOffers} : sidebarProps) {
    return <div className={`fixed w-72 z-[100] h-52 bg-white transition-all duration-700 top-0 ${show ? 'right-0 opacity-100' : '-right-72 opacity-0'}`}>
        <button onClick={toggle} className="absolute right-2 top-5"><img src={close} alt="close"/></button>
        <div className="flex flex-col gap-5 items-center justify-end pb-8 h-full">
            <button  onClick={() => toggleDiscoverOffers(true)} className="font-semibold w-48 py-2 px-4 border border-[#2C1749] text-[#2C1749]">Discover our offers</button>
            <button  onClick={() => toggleContactModal(true)} className="font-semibold w-48 py-2 px-4 border bg-[#BEADD5] text-[#2C1749] border-[#BEADD5]">Contact Us</button>
        </div>
    </div>
}