import Modal from ".";
import pdf from '../files/discovers.pdf';

interface DiscoverOffersProps {
    show: boolean;
    toggle: () => void;
}

export default function DiscoverOffers({ show, toggle }: DiscoverOffersProps) {
    return (
        <Modal show={show} toggle={toggle}>
            <div className="bg-white p-5 lg:p-10 rounded-lg" data-aos="fade-up">
                <p className="font-fine text-3xl lg:text-4xl w-full max-w-sm mb-5">You are about downloading our service breakdown</p>

                <a href={pdf} download className="font-semibold block text-center w-full py-3 px-4 border border-[#2C1749] text-[#2C1749]">Proceed To Download</a>
            </div>
        </Modal>
    );
}