import { facebook, instagram, logo2, twitter } from "../assets";

export default function Footer() {
    return <footer className="flex flex-col items-center gap-5 py-10 text-white">
        <img src={logo2} alt="logo" className="w-36" />

        <p className="font-fine text-2xl mt-5">Connect With Us</p>
        <ul className="flex gap-4">
            <li>
                <a href="https://www.facebook.com/profile.php?id=100095397584677" target="_blank" rel="noopener noreferrer"><img className="w-10" src={facebook} alt="facebook" /></a>
            </li>
            <li>
                <a href="https://twitter.com/Brandomidotorg?t=GmqfxcqvD8mGCrmSWgoDwg&s=09" rel="noopener noreferrer" target="_blank"><img className="w-10" src={twitter} alt="twitter" /></a>
            </li>
            <li>
                <a href="https://instagram.com/brandomidotorg?igshid=MzMyNGUyNmU2YQ==" target="_blank" rel="noopener noreferrer"><img className="w-10" src={instagram} alt="instagram" /></a>
            </li>
        </ul>
 
        <p>© {new Date().getFullYear()} Brandomi.org. Copyright reserved</p>
    </footer>
}