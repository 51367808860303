import Modal from ".";
import CopyText from "../widgets/CopyText";

interface ContactModalProps {
    show: boolean,
    toggle: () => void
}

export default function ContactModal({ show, toggle }: ContactModalProps) {
    return <Modal show={show} toggle={toggle}>
        <div className="bg-white p-5 lg:p-10 rounded-lg" data-aos="fade-up">
            <p className="font-fine text-3xl lg:text-4xl w-full max-w-sm mb-5">Never a bad time to reach out to us!</p>

            <ul className="grid gap-3">
                <li>
                    <CopyText
                        text="+2349036908590 {Teju}"
                        textToCopy="+2349036908590"
                    />
                </li>
                <li>
                    <CopyText
                        text="+2347032980869 {David}"
                        textToCopy="2347032980869"
                    />
                </li>
                <li>
                    <CopyText
                        text="brandomi.org@gmail.com"
                        textToCopy="brandomi.org@gmail.com"
                    />
                </li>
            </ul>
        </div>
    </Modal>
}