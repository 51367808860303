import React from 'react';
import Slider, { Settings } from 'react-slick';



export default function Carousel({
    children
}: React.PropsWithChildren) {
    const settings: Settings = {
        dots: true,
        autoplay: false,
        slidesToShow: 2,
        centerMode: true,
        nextArrow : <></>,
        prevArrow : <></>,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        arrows: false,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              }
            },
            // {
            //   breakpoint: 600,
            //   settings: {
            //     slidesToShow: 2,
            //     slidesToScroll: 2,
            //     initialSlide: 2
            //   }
            // },
        ]
    }
    return <Slider {...settings}>
        {
            children
        }
    </Slider>
}

export function CarouselTab({
    children
}: React.PropsWithChildren) {
    return <div className='flex items-end h-[35rem] md:h-[55rem]'>
        <div className='w-[95%]'>
            {children}
        </div>
    </div>
}