import React from "react";
import { tick2 } from "../../assets";
import type { plan } from "../../constants/priceplans";


export default function PlanCard({
    data: {
        name,
        sub_fee,
        price,
        description,
        details
    }
}: { data: plan }) {
    const [onHover, setOnHover] = React.useState(false);

    return <div
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        className={`plan-card w-full  ${ onHover ? "lg:bg-[#C094FD] lg:text-[white] bg-white text-[#0F0122]" : "bg-white text-[#0F0122]"} transition-all duration-700 p-5 rounded-xl flex flex-col items-center gap-5 overflow-hidden relative h-fit self-end`}>
        <span className={`bg-[#CCA8FF] w-[1300px] h-[1300px] rounded-full absolute transition-all duration-700  ${onHover ? "lg:top-[130px] top-[1500px]" : "top-[1500px]"}` } />
        <p className="text-xl lg:text-3xl font-semibold relative">{name}</p>
        <p className={`${onHover ? "lg:text-white text-[#A6A6A6]" : "text-[#A6A6A6]"} transition-all duration-700 text-sm lg:text-lg max-w-[12rem] lg:max-w-[15rem] text-center font-semibold relative`}>{description}</p>

        <p className={`${onHover ? "lg:text-[#6B42A4] lg:bg-white bg-[#6B42A4] text-white" : "bg-[#6B42A4] text-white"} lg:text-base text-sm transition-all duration-700 w-fit p-2 rounded-lg font-semibold text-center relative`}>Subscription Fee: {sub_fee}</p>

        <p className="text-3xl lg:text-5xl font-semibold relative"><sup className={`text-base lg:text-lg ${onHover ? "lg:text-white text-[#A6A6A6]" : "text-[#A6A6A6]"} transition-all duration-700 relative -top-5`}>NGN</sup>{price}</p>

        <ul className={`w-full p-3 relative rounded-lg bg-white text-[#0F0122] flex flex-col gap-4 transition-all duration-700 overflow-hidden ${onHover ? "h-80 md:h-[30rem] lg:h-[38rem]" : "h-80 md:h-[30rem] lg:h-96"}`}>
            {
                details.map(
                    (detail, index) =>
                        <li className="flex items-start gap-2"
                            key={"detailsfor" + name + index}><img src={tick2} alt="tick" className="w-3 md:w-5 relative top-2 md:top-1" />
                            <aside className="!bg-transparent">
                                <p className="text-[#0F0122]  md:text-sm font-semibold">{detail.name}</p>
                                <p className="text-[#0F0122] text-[12px] md:text-sm font-semibold ">{detail.sub_name}</p>
                                <p className="text-[#0F0122] text-[12px] leading-4 md:text-xs md:leading-6">{detail.description}</p>
                            </aside>
                        </li>
                )
            }
        </ul>

    </div>
}