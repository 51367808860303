import React from "react";
import { hamburger, logo } from "../assets";
import Sidebar from "./widgets/Sidebar";

interface NavBarProps {
    toggleDiscoverOffers: React.Dispatch<React.SetStateAction<boolean>>,
    toggleContactModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function NavBar(
    { toggleDiscoverOffers, toggleContactModal }: NavBarProps
) {
    const [show, setShow] = React.useState(false);
    function handleScroll() {
        if (window.scrollY > 100) {
            setShow(false)
        }
    }
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return function cleanup() {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return <nav className="bg-white sticky top-0 z-[100] shadow-lg">
        <div className="brandomi-container py-3 flex justify-between items-center top-0 ">
            <img src={logo} alt="logo" className="w-36" />

            <div className="md:flex gap-3 hidden">
                <button onClick={() => toggleDiscoverOffers(true)} className="font-semibold py-2 px-4 border border-[#2C1749] text-[#2C1749]">Discover our offers</button>
                <button onClick={() => toggleContactModal(true)} className="font-semibold py-2 px-4 border bg-[#BEADD5] text-[#2C1749] border-[#BEADD5]">Contact Us</button>
            </div>

            <button className="block md:hidden" onClick={() => setShow(true)}><img src={hamburger} className="" alt="menu" /></button>
        </div>

        <Sidebar show={show} toggle={() => setShow(false)} toggleContactModal={toggleContactModal}  toggleDiscoverOffers={toggleDiscoverOffers} />
    </nav>
}