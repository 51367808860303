import { abstract2, tick } from "../assets";

export default function Services() {
    const services = [
        "Lead Generation and Acquisition",
        "Content Creation",
        "Social Media Management",
        "Software Development",
        "Brand Strategy and Branding"
    ]
    return <section className="brandomi-container py-10 flex gap-10 md:gap-10 flex-col-reverse md:flex-row items-center text-white">
        <img className="w-2/3 md:w-1/2" alt="abstract" src={abstract2} />

        <div className="w-full">
            <h1 className="text-3xl lg:text-5xl xl:text-6xl font-fine relative font-semibold">Our Services Include:</h1>

            <ul className="grid gap-8 mt-12">
                {
                    services.map((service, index) => <>
                        <li key={"Service" + index}
                        className="flex items-center md:text-lg gap-4">
                            <img src={tick} alt="tick" className=" h-5 md:h-7" />
                            {service}</li>
                    </>)
                }
            </ul>
        </div>
    </section>
}