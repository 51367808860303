import React from "react";

interface GetStartedFormProps {
    toggleDiscoverOffers: React.Dispatch<React.SetStateAction<boolean>>
}

export default function GetStartedForm( { toggleDiscoverOffers }: GetStartedFormProps) {
    const [formData, setFormData] = React.useState({
        email: "",
        message: ""
    })

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    return <div className="bg-[#222938] lg:mt-0 mt-10 rounded-lg flex flex-col p-5 items-center justify-center gap-5">
        <p className="font-semibold text-2xl">Get Started</p>

        <div className="max-w-md w-full">
            <label className="mb-3 block font-semibold">Email</label>
            <input value={formData.email} onChange={handleChange} name="email" className="w-full h-12 focus:outline-none text-[#0F0122] px-3 " placeholder="Enter your email" />
        </div>

        <div className="max-w-md w-full">
            <label className="mb-3 block font-semibold">Message</label>
            <textarea value={formData.message} onChange={handleChange} name="message" className="w-full !h-28 focus:outline-none text-[#0F0122] p-3" placeholder="How may we be of service"/>
        </div>

        <a href={`mailto:brandomi.org@gmail.com?subject=Brandomi Enquiry&body=${formData.message}!`} className="bg-[#54BD95] max-w-md w-full h-12 flex justify-center items-center"
            onClick={(e) => {
                if (formData.email === "" || formData.message === "") {
                    e.preventDefault();
                }
            }}
        >Send Message</a>

        <button className="text-right w-full max-w-md" onClick={() => toggleDiscoverOffers(true)}>
            <span className="italic text-[#A6A6A6]">or</span> Discover our offers
        </button>
    </div>
}