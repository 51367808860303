import React from "react";
import Aos from 'aos';
import 'aos/dist/aos.css';
import Affiliations from "./components/Affiliations";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import Priceplan from "./components/Priceplan";
import Services from "./components/Services";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SubFooter from "./components/SubFooter";
import Footer from "./components/Footer";
import ContactModal from "./components/modal/ContactModal";
import DiscoverOffers from "./components/modal/DiscoverOffers";
import Recruitment from "./components/Recruitment";

function App() {
  const [showContactModal, setShowContactModal] = React.useState(false);
  const [showDiscoverOffers, setShowDiscoverOffers] = React.useState(false);

  React.useEffect(() => {
    Aos.init();
  }, [])
  return (
    <>
      <NavBar toggleContactModal={setShowContactModal}  toggleDiscoverOffers={setShowDiscoverOffers}/>
      <Header  toggleDiscoverOffers={setShowDiscoverOffers} />
      <Affiliations />
      <Services />
      <Priceplan />
      <Recruitment />
      <SubFooter  toggleDiscoverOffers={setShowDiscoverOffers} />
      <Footer />

      <ContactModal show={showContactModal} toggle={() => setShowContactModal(false)} />
      <DiscoverOffers show={showDiscoverOffers} toggle={() => setShowDiscoverOffers(false)} />
    </>
  );
}

export default App;
