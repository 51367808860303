import { partner1, partner2, partner3, partner4, partner5, star2, threeSquares } from "../assets";

export default function Affiliations() {
    const affiliations = [partner1, partner2, partner3, partner4, partner5];

    return <section className="bg-[#2C1749] text-white">
        <div className="brandomi-container lg:gap-0 gap-8 flex flex-col lg:flex-row justify-between relative pt-24 pb-10">
            <img src={threeSquares} alt="three squares" className="absolute top-0 h-10 left-[calc(100%-12.5rem)]
            md:left-[23.3rem] min-[992px]:left-[37rem] lg:left-[27.5rem] min-[1200px]:left-[40rem]"/>
            <span className="border-b lg:border-b-[3px] border-white absolute top-10 w-[calc(100%-14rem)] md:w-[calc(100%-25rem)] lg:w-[calc(100%-34.5rem)]"/>
            <div className="flex md:justify-start justify-between md:gap-12">
                {
                    affiliations.map((affiliation, index) => <div key={"affiliations" + index}
                        className="bg-[#6B42A4] rounded-md w-[14%]  md:w-16 aspect-square shrink-0 p-1 lg:p-2"
                    >
                        <img src={affiliation} alt="affiliation" className="w-full h-full" />
                    </div>)
                }
            </div>

            <div className="flex gap-4 lg:gap-8 items-center">
                <img alt="star" src={star2} className="w-10 lg:w-auto" />
                <p className="max-w-xs text-sm sm:text-sm md:text-md ">Members of Brandomi have helped these companies and many others achieve their goals and objectives .</p>
            </div>
        </div>
    </section>
}